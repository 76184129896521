import styled from 'styled-components'

export const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column-reverse')};
  align-items: stretch;
  /* justify-content: ${({ bigScreen }) =>
    bigScreen ? 'center' : 'space-between'}; */
    justify-content: center;
    background: #f4f4f4;
    `
export const MainContainer = styled.div`
  width: 100%;
  /* max-width: 1200px; */
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column-reverse')};
  justify-content: ${({ bigScreen }) => (bigScreen ? 'flex-end' : 'center')};
  /* justify-content: center; */
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: center;
`

export const Container = styled.div`
  background-color: #66af70 !important;
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  padding: ${({ isDesktop }) => (isDesktop ? '3rem' : '3rem 1rem')};
  flex-basis: 82%;
  justify-content: center;
`

export const ImgContainer = styled.div`
  background-color: #f4f4f4;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: ${({ isDesktop }) => (isDesktop ? '100%' : '50%')};
  }
`
export const Right = styled.div`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column-reverse')};
`
