import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: ${({ isDesktop }) => (isDesktop ? '100%' : '350%')};
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'row')};
  flex-wrap: nowrap;
  justify-content: ${({ isDesktop }) =>
    isDesktop ? 'space-between' : 'flex-start'};
  overflow-x: ${({ isDesktop }) => (isDesktop ? 'hidden' : 'scroll')};
  ::-webkit-scrollbar {
    display: none;
  }
  gap: ${({ isDesktop }) => (isDesktop ? '0' : '2rem')};
`
export const Card = styled.div`
  width: 320px;
  padding: 1rem;

  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(36, 50, 88, 0.11);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
  gap: 3rem;
`
export const CardHeader = styled.div`
  img {
    object-fit: cover;
    object-fit: cover;
    width: 290px;
    border-radius: 20px;
  }
`
export const CardBody = styled.div`
  h3 {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 27px;
    color: #66af70;
  }
  p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    color: #272042;
  }
  button {
    margin-top: 1rem;
    width: 100%;
  }
`
