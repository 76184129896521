import React from "react";
import { MOBILE_WIDTH } from "~/utils/constants";
import useMediaQuery from "~/hooks/useMediaQuery";
import * as Styled from "./styles";
import { useCountUp } from 'react-countup'
import NewButton from "../NewButton/index";

export default function Numbers() {
    const { countUp: countPrescriptions } = useCountUp({
        end: 554,
        duration: 5,
        delay: 3,
      })
      const { countUp: cbdUsers } = useCountUp({
        end: 77,
        duration: 5,
        delay: 3,
      })
      const {countUp: studies} = useCountUp({
        end: 27000,
        duration: 5,
        delay: 3,
      })
      const {countUp: growuth} = useCountUp({
        end: 105,
        duration: 5,
        delay: 3,
      })
      const {countUp: invoicing} = useCountUp({
        end: 13,
        duration: 5,
        delay: 3,
      })
      const {countUp: jobs} = useCountUp({
        end: 300,
        duration: 5,
        delay: 3,
      })

    const isMobile = useMediaQuery(MOBILE_WIDTH);

    return (
        <Styled.Wrapper isDesktop={!isMobile}>
            <h1>Cannabis medicinal em números:</h1>
            <Styled.Container isDesktop={!isMobile}>
                <p>A prescrição de canabinoides já é uma realidade no Brasil.<br/>
Mas <span>menos de 1% dos médicos com CRM ativo prescrevem.</span></p>
            </Styled.Container>

            <Styled.NumbersWrapper isDesktop={!isMobile}>
                <Styled.NumbersContainer>
                  <Styled.NumberCountainer isDesktop={!isMobile}>
                      <p className="number">{countPrescriptions}<span>%</span></p>
                      <p className="text">Foi o crescimento da prescrição médica de cannabinoides no Brasil</p>
                  </Styled.NumberCountainer>
                  <Styled.NumberCountainer isDesktop={!isMobile}>
                      <p className="number">{cbdUsers}<span>%</span></p>
                      <p className="text">dos brasileiros usariam o tratamento com canabinoides, se receitado por um médico</p>
                  </Styled.NumberCountainer>
                  <Styled.NumberCountainer isDesktop={!isMobile}>
                      <p className="number">{studies}<span>+</span></p>
                      <p className="text">estudos publicados no PubMed sobre o tema.</p>
                  </Styled.NumberCountainer>
                </Styled.NumbersContainer>
                <Styled.NumbersContainer>
                  <Styled.NumberCountainer isDesktop={!isMobile}>
                      <p className="number">US$ {growuth} <span>bilhões</span></p>
                      <p className="text">deve ser o crescimento do mercado mundial de cannabis até 2026</p>
                  </Styled.NumberCountainer>
                  <Styled.NumberCountainer isDesktop={!isMobile}>
                  <p className="number">US$ {invoicing} <span>milhões</span></p>
                      <p className="text">é o faturamento anual potencial do mercado de cannabis brasileiro</p>
                  </Styled.NumberCountainer>
                  <Styled.NumberCountainer isDesktop={!isMobile}>
                      <p className="number">{jobs}<span> mil empregos</span></p>
                      <p className="text">é o potencial para o mercado da cannabis no Brasil</p>
                  </Styled.NumberCountainer>
                </Styled.NumbersContainer>
            </Styled.NumbersWrapper>
            {/* <a href="https://educacao.drcannabis.com.br/" target="_blank">
              <button>Veja os cursos</button>
            </a> */}
            <div id="buttonBottom">
              <NewButton href="https://educacao.drcannabis.com.br/" target="_blank" label="Veja os cursos" />
            </div>
        </Styled.Wrapper>
    )
}