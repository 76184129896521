import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column-reverse')};
  align-items: center;
  justify-content: space-between;
  max-width: ${({ isDesktop }) => (isDesktop ? '1200px' : '100%')};
`

export const Container = styled.div`
  width: ${({ isDesktop }) => (isDesktop ? '50%' : '100%')};
  div {
    padding: ${({ isDesktop }) => (isDesktop ? '0 0 0 2.5rem' : '0 1rem')};
    h1 {
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 60px;
      color: #444444;
      span {
        color: #66af70;
      }
    }
    p {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: ${({ isDesktop }) => (isDesktop ? '1rem' : '18px')};
      line-height: ${({ isDesktop }) => (isDesktop ? '30px' : '36px')};

      text-align: justify;
      letter-spacing: -0.015em;

      color: #0d1733;
      margin: 1rem 0;
    }
  }
  img {
    width: 100%;
  }
`
