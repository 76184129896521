import React from 'react'
import { MOBILE_WIDTH } from '~/utils/constants';
import useMediaQuery from '~/hooks/useMediaQuery';
import cardImgOne from '~/assets/images/newhome/cardImgOne.png';
import cardImgTwo from '~/assets/images/newhome/cardImgTwo.png';
import cardImgThree from '~/assets/images/newhome/cardImgThree.png';
import vejaTodos from '~/assets/images/newhome/vejaTodos.png'
import * as Styled from './styles';
import NewButton from '../NewButton/index';
import LTMobile from './LTMobile/index';

export default function LearningTrails() {
    const isMobile = useMediaQuery(MOBILE_WIDTH);
    return (
        <Styled.Container isDesktop={!isMobile}>
            <Styled.Header isDesktop={!isMobile}>
                <h1>Conheça nossas trilhas de aprendizagem</h1>
            </Styled.Header>
            <Styled.CardsContainer isDesktop={!isMobile}>
                {
                    !isMobile ? (
                        <>
                        <Styled.Card isDesktop={!isMobile}>
                        <Styled.CardHeader isDesktop={!isMobile}>
                            <img src={cardImgOne} alt="" />
                        </Styled.CardHeader>
                        <Styled.CardBody isDesktop={!isMobile}>
                            <h3>Negócios</h3>
                            <p>
                            Um mercado com capacidade de gerar 300 mil empregos e movimentar 13 milhões de dólares no Brasil está em formação. 
    Empreendendo ou trabalhando na área, comece hoje a se destacar.
                            </p>
                        </Styled.CardBody>
                        {/* <button>Quero trabalhar com Cannabis</button> */}
                        <NewButton label="Quero trabalhar com Cannabis" href="https://educacao.drcannabis.com.br/cursos#section_1667925491839_2031" newTab />
                    </Styled.Card>
                    <Styled.Card isDesktop={!isMobile}>
                        <Styled.CardHeader isDesktop={!isMobile}>
                            <img src={cardImgTwo} alt="" />
                        </Styled.CardHeader>
                        <Styled.CardBody isDesktop={!isMobile}>
                            <h3>Médicos e odontologistas</h3>
                            <p>
                            Prescrever canabinoides é legal desde 2015, mas apenas 1% dos profissionais que poderiam já prescrevem na prática.
    Seja pioneiro e se diferencie!
                            </p>
                        </Styled.CardBody>
                        <NewButton label="Quero ser prescritor" href='https://educacao.drcannabis.com.br/cursos#el_1667920665798_517' newTab />
                    </Styled.Card>
                    <Styled.Card isDesktop={!isMobile}>
                        <Styled.CardHeader isDesktop={!isMobile}>
                            <img src={cardImgThree} alt="" />
                        </Styled.CardHeader>
                        <Styled.CardBody isDesktop={!isMobile}>
                            <h3>Profissionais da Saúde</h3>
                            <p>
                            Depois da prescrição, a resposta terapêutica é percebida pelo paciente. Psicólogos e enfermeiros precisam estar prontos para acolher essas mudanças.
                            </p>
                        </Styled.CardBody>
                        {/* <button>Quero me capacitar</button> */}
                        <NewButton label="Quero me capacitar" href="https://educacao.drcannabis.com.br/cursos#el_1667925498693_2077" newTab  />
                    </Styled.Card>
                
                        </>
                    ) : <LTMobile />
                }
            </Styled.CardsContainer>
                <div id='moreCourses'>
                    <a href="https://educacao.drcannabis.com.br/">Veja todos os cursos <span><img src={vejaTodos} alt="" /></span></a>
                </div>
        </Styled.Container>
    )
}