import React, { useState } from 'react'
import useMediaQuery from '~/hooks/useMediaQuery'
import { MOBILE_WIDTH } from '~/utils/constants'
import { SectionNewsletter } from './styles'

import Button from '~/components/Button'
import api from '~/services/api'

export default function FooterNewsletter() {
  const [emailState, setEmailState] = useState('')
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <>
      <SectionNewsletter
        className="d-md-none pt-4 pb-5 pl-2 pr-2"
        isDesktop={!isMobile}
      >
        <h3 className="title">
          A informação é o melhor remédio:
          <br />
          <span>Receba nossa newsletter</span>
        </h3>
        <form action="">
          <div className="d-flex justify-content-center pr-2">
            <div className="box lg mr-3">
              <label htmlFor={'email'} className={'d-none'}>
                email
              </label>
              <input
                className="text pl-3 pr-3"
                type="text"
                name="email"
                placeholder="E-mail"
                value={emailState}
                onChange={e => {
                  setEmailState(e.currentTarget.value)
                }}
              />
              <Button
                onClick={() =>
                  api.post('/users', {
                    isResponsible: false,
                    passwordConfirm:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
                    profile: {
                      full_name: 'newsletterAdmin',
                    },
                    user: {
                      password:
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15),
                      email: emailState,
                    },
                    role: 'patient',
                  })
                }
              >
                Receber
              </Button>
            </div>
          </div>
        </form>
      </SectionNewsletter>

      <SectionNewsletter className="d-none d-md-block pt-5 pb-5">
        <h3 className="title">
          A informação é o melhor remédio:
          <br />
          <span>Receba nossa newsletter</span>
        </h3>
        <form action="">
          <div className="d-flex justify-content-center">
            <div className="box lg mr-3">
              <label htmlFor={'email'} className={'accessibility'}>
                email
              </label>
              <input
                className="text pl-3 pr-3"
                type="text"
                name="email"
                id="email"
                placeholder="E-mail"
                value={emailState}
                onChange={e => {
                  setEmailState(e.currentTarget.value)
                }}
              />
              <Button
                className="mt-3 md text--sr"
                onClick={() =>
                  api.post('/users', {
                    isResponsible: false,
                    passwordConfirm:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
                    profile: {
                      full_name: 'newsletterAdmin',
                    },
                    user: {
                      password:
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15),
                      email: emailState,
                    },
                    role: 'patient',
                  })
                }
              >
                Receber
              </Button>
            </div>
          </div>
        </form>
      </SectionNewsletter>
    </>
  )
}
