import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  /* max-width: 1080px; */
  /* min-height: 100vh; */
  /* height: 700px; */
  padding: 80px 2rem 4rem;
`

// export const Gray = styled.div`
//   position: absolute;
//   bottom: -110px;
//   left: 0px;
//   z-index: 99;
//   width: 100%;
//   div {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     border-radius: 50% 50% 0% 50%;
//     padding: 0 20px;
//     width: 454px;
//     height: 464px;
//     background: #696969;
//     img {
//       max-width: 100%;
//     }
//   }
//   flex-basis: 50%;
// `

// export const Green = styled.div`
//   position: absolute;
//   left: 350px;
//   bottom: -140px;
//   z-index: 9999999999999;
//   background: #66af70;
//   border-radius: 276.166px 276.166px 276.166px 0px;
//   div {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 0 20px;
//     width: 225px;
//     height: 230px;

//     img {
//       max-width: 100%;
//     }
//   }
// `
export const bubbleContainer = styled.div`
  /* position: relative; */
  /* width: 50%; */
  img {
    max-width: 100%;
    max-height: 332px;
  }
`

export const textContainer = styled.div`
  flex-basis: ${({ isDesktop }) => (isDesktop ? '55%' : '100%')};
  /* padding-left: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;

  h1 {
    font-family: 'Spartan';
    font-style: normal;
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 45px;
    span {
      color: #66af70;
      font-weight: 700;
    }
  }
  p {
    margin-top: 5px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: ${({ isDesktop }) => (isDesktop ? '32px' : '24px')};
  }
  .cta {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
    justify-content: ${({ isDesktop }) =>
      isDesktop ? 'flex-start' : 'center'};
    align-items: center;
    gap: 1rem;
    a {
      width: ${({ isDesktop }) => (isDesktop ? 'auto' : '100%')};
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      /* font-size: 1rem; */
      /* line-height: 24px; */
      color: #66af70;
      text-decoration: none;
      margin-right: 10px;
      button {
        width: 100%;
      }
    }
    /* button {
      border-radius: 5px;
      border: none;
      padding: 7px 12px;
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 600;
      font-size: 0.8rem;
      line-height: 32px;
      text-transform: uppercase;
      border-radius: 10px;
    }
    .cannectCta {
      background: #fff;
      border: #1c2960 2px solid;
      color: #1c2960;
      font-weight: bold;
    }
    .scheduleCta {
      background: #1c2960;
      border: #1c2960 2px solid;
      color: #fff;
      font-weight: bold;
    } */
  }
`
