import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 60px;
  background-color: ${({ isDesktop }) => (isDesktop ? '' : '#fff')};
  height: ${({ isDesktop }) => (isDesktop ? 'auto' : '90px')};
`

export const Container = styled.div`
  width: 100%;
  max-width: ${({ isDesktop }) => (isDesktop ? '1200px' : '100%')};
  display: ${({ isDesktop }) => (isDesktop ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 5rem;
  img {
    max-width: 200px;
  }
  nav {
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 1rem;

      li {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 33px;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        color: #444444;
      }
    }
    margin-right: 1rem;
    margin-top: 1rem;
  }
  a {
    margin-top: 1rem;
  }
  button {
    background: #1c2960;
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
    color: #fff;
    padding: 0.9rem 1.3rem;
    border: none;
    border-radius: 10px;
  }
`

export const Logo = styled.div``

export const MobileMenu = styled.div`
  overscroll-behavior: fixed;
  width: 100%;
  display: ${({ isDesktop }) => (isDesktop ? 'none' : 'flex')};
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  gap: 1rem;
  position: absolute;
  top: 1rem;
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      position: fixed;
      .mobileMenuToggle {
      }
    `};
  .mobileMenuToggle {
    /* position: ${({ isMenuOpen }) => (isMenuOpen ? 'fixed' : 'static')}; */
    /* top: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '0')};
    right: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '0')}; */
    /* top: 20px; */
    /* right: 20px; */
    /* z-index: 99999999999999; */
    position: absolute;
    top: 20px;
    right: 30px;
  }
`
export const MobileMenuContent = styled.div`
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'flex' : 'none')};
  padding-top: 7rem;
  position: fixed;
  top: 0;
  width: 100vw;
  background: #fff;
  height: 100vh;
  z-index: 999999;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 1rem;
  padding-left: 1rem;
  .mobileMenuToggle {
    position: absolute;
    top: 30px;
    right: 30px;
  }
  nav {
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      align-content: flex-start;
      gap: 1rem;
      li {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 33px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #444444;
      }
    }
  }
  button {
    background: #1c2960;
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #fff;
    padding: 0.9rem 1.3rem;
    border: none;
    border-radius: 10px;
  }
`
