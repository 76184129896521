import styled from 'styled-components'

export const Wrapper = styled.div`
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 60px;
  padding: ${({ isDesktop }) => (isDesktop ? '1rem 2.5rem' : '0 1rem')};

  button {
    /* font-family: 'Spartan';
    font-style: normal;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem; */
    /* line-height: 24px; */

    /* color: #ffffff;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 21.7339px 29.3407px 16.3004px;
    gap: 10.87px; */

    /* width: 300px; */
    /* height: 65.5px; */

    /* background: #1c2960;
    box-shadow: 7.60685px 8.69355px 33.6875px rgba(20, 62, 101, 0.11);
    border-radius: 10.8669px;
    border: none; */
    display: block;
    margin: 50px auto;
    width: 326px;
    text-transform: uppercase;
  }
  h1 {
    font-size: 2rem;
    margin: 20px 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  /* padding: 1rem; */
  width: 100%;
  /* height: 100%; */
  margin-top: 1rem;
`

export const Card = styled.div`
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 4.32963px 22.7305px rgba(36, 50, 88, 0.11);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${({ isDesktop }) => (isDesktop ? '500px' : '100%')};
  height: ${({ isDesktop }) => (isDesktop ? '500px' : '100%')};
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  gap: 1rem;
  /* &:hover {
    transform: scale(1.05);
  } */

  iframe {
    /* margin: 1rem; */
    width: 100%;
    height: ${({ isDesktop }) => (isDesktop ? '56%' : '200px')};
    border: none;
    border-radius: 21px;
  }
`

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
  width: 100%;
  padding: 1rem;
  h3 {
    width: 100%;
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: ${({ isDesktop }) => (isDesktop ? '1rem' : '16px')};
    line-height: 38px;

    letter-spacing: 0.02em;

    color: #444444;
  }
  p {
    margin-top: 2.5rem;
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 600;
    font-size: ${({ isDesktop }) => (isDesktop ? '0.9rem' : '14px')};
    line-height: 28px;

    letter-spacing: 0.02em;

    color: #7a7a7a;
  }
`
export const TestiMonialWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 2.5rem 0rem;
`

export const CardTestimonial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30.3188px;
  gap: 16px;

  /* width: 489px;
  height: 400px; */

  max-width: 375px;
  height: 450px;

  background: #3a978e;
  border-radius: 20px;
  color: #fff;

  img {
    border-radius: 100%;
  }
  div {
    .drName {
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 45px;
      letter-spacing: 0.02em;
    }
    .drData {
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 29px;
      letter-spacing: 2%;
    }
  }
  p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    letter-spacing: 0.02em;
  }
`
