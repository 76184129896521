import React from 'react'
import { MOBILE_WIDTH } from '~/utils/constants';
import useMediaQuery from '~/hooks/useMediaQuery';
import cannectLogo from '~/assets/svg/cannectLogo.svg'
import drcWhiteLogo from '~/assets/svg/drcWhiteLogo.svg'
import bublesCannect from '~/assets/images/newhome/bublesCannect.png'
import * as Styled from './styles';
import NewButton from '../NewButton/index';

export default function CannectSection() {
    const isMobile = useMediaQuery(MOBILE_WIDTH);
    return (
        <Styled.Container isDesktop={!isMobile}>
            <Styled.bubbleContainer isDesktop={!isMobile}>
                <img src={bublesCannect} alt="" />
            </Styled.bubbleContainer>
            <Styled.textContainer isDesktop={!isMobile}>
                <h1>
                    <span>DR. CANNABIS </span>+ <span>CANNECT</span> unidas pelo <span>mesmo ideal</span>
                </h1>
                <p>
                A Dr. Cannabis agora faz parte do ecossistema da Cannect, a maior plataforma de acesso a Cannabis Medicinal do Brasil.
                </p>
                <div className='cta'>
                    <NewButton href="https://cannect.life" newTab outlined label="CONHEÇA A CANNECT" />
                    <NewButton href="https://www.cannect.life/profissionais-parceiros-cannabis-medicinal" newTab label="AGENDE UMA CONSULTA" />
                </div>
            </Styled.textContainer>
        </Styled.Container>
    )
}