import React from "react";
import { MOBILE_WIDTH } from "~/utils/constants";
import useMediaQuery from "~/hooks/useMediaQuery";
import drceventsnewhome from "~/assets/images/newhome/drceventsnewhome.png";
import drceventsnewhomeMobile from "~/assets/images/newhome/drceventsnewhomeMobile.png";
import cannaBusiness from "~/assets/images/newhome/cannaBusiness.png";
import cnabis from "~/assets/images/newhome/cnabis.png";
import * as Styled from "./styles";

export default function Events() {
    const isMobile = useMediaQuery(MOBILE_WIDTH);
    return (
        <Styled.Wrapper isDesktop={!isMobile} >
            <h1>Eventos organizados pela Dr. Cannabis</h1>
            <Styled.Container isDesktop={!isMobile}>
            {!isMobile ? <img src={drceventsnewhome} alt="" /> : <img src={drceventsnewhomeMobile} alt="" />}
            <div className="text">
                <div>
                    {
                        !isMobile && (
                            <div>
                                <img src={cannaBusiness} alt="" />
                            </div>
                        )
                    }
                    <div>
                        <h3>Congresso de Cannabis Medicinal - CNABIS</h3>
                        <p>Congresso técnico-científico sobre cannabis medicinal com curadoria de alto nível e palestrantes do Brasil e do mundo</p>
                    </div>
                </div>
                <div>
                    {
                        !isMobile && (
                            <div>
                                <img src={cnabis} alt="" style={{marginLeft: '-4px'}} />
                            </div>
                        )
                    }
                    <div>
                        <h3>CannaBusiness Summit 2022</h3>
                        <p>Congresso de negócios sobre cannabis que promete posicionar os participantes na vanguarda deste mercado promissor.</p>
                    </div>
                </div>
            </div>
            </Styled.Container>
        </Styled.Wrapper>
    )
}