import styled from 'styled-components'

export const Container = styled.button`
  box-sizing: border-box;
  background-color: ${({ outlined }) => (outlined ? 'transparent' : '#1C2960')};
  color: ${({ outlined }) => (outlined ? '#1C2960 !important' : '#fff')};
  /* border: ${({ outlined }) =>
    outlined ? '2px solid #1C2960 !important' : 'none'}; */
    border: 2px solid #1c2960 !important;
  padding: 0.8rem 1rem;
  border-radius: 10px;
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 0.9rem;
`
