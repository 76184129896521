import React from "react";
import Sprites from '~/assets/icons/sprites.svg'
import { Link } from "react-router-dom";
import * as Styled from './styles';
import FooterLinkComponent from "./FooterLinkComponent/index";
import { MOBILE_WIDTH } from "~/utils/constants";
import useMediaQuery from "~/hooks/useMediaQuery";

export default function FooterLinks() {
  const isMobile = useMediaQuery(MOBILE_WIDTH);
    return (
      <Styled.Wrapper isDesktop={!isMobile} >
        <h1>Acesso rápido</h1>
        <Styled.Container isDesktop={!isMobile}>
          <ul>
            <FooterLinkComponent link="https://educacao.drcannabis.com.br/cursos" target="_blank" title="Cursos" />
            <FooterLinkComponent link="/faq" title="FAQ" />
          </ul>
        </Styled.Container>
      </Styled.Wrapper>
   )
}