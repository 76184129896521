import React from "react";
import { MOBILE_WIDTH } from "~/utils/constants";
import useMediaQuery from "~/hooks/useMediaQuery";
import cbdPic from "~/assets/images/newhome/imagem-sobre-cannabis.png";
import * as Styled from "./styles";

export default function Oportunities() {
    const isMobile = useMediaQuery(MOBILE_WIDTH);
    return (
        <Styled.Wrapper isDesktop={!isMobile}>
            <Styled.Container isDesktop={!isMobile}>
                <div>
                    <h1>Sobre oportunidades no mercado de <span>Cannabis</span></h1>
                    <p>A cannabis medicinal é legal e uma importante ferramenta terapêutica para tratar doenças crônicas e sintomas associados. A atual literatura científica já fornece diferentes níveis de evidência para sustentar o uso dos canabinoides em uma variedade de condições de saúde como dor crônica, Alzheimer, ansiedade, fibromialgia, autismo, Parkinson, epilepsia e muitas outras.</p>
                    <p>O acesso é legal no Brasil desde 2015 e as prescrições cresceram mais de 500% desde então, ainda assim há uma demanda reprimida por profissionais das mais diversas áreas que dominem o desafio e proponham soluções para desenvolver este mercado de enorme potencial.</p>
                    <p>A Dr Cannabis é a plataforma de educação criada para profissionais que querem alavancar sua carreira no mercado de cannabis. Faça parte da nossa comunidade! </p>
                </div>
            </Styled.Container>
            <Styled.Container isDesktop={!isMobile}>
                <img src={cbdPic} alt="" />
            </Styled.Container>
        </Styled.Wrapper>
    )
}