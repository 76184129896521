import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 3rem;
  /* padding: 0 2.5rem; */
  margin-top: 50px;
  width: 275%;
`

export const CardTestimonial = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  width: 300px;
  height: 380px;
  padding: 1rem;
  color: #fff;
  background: #3a978e;

  img {
    border-radius: 100%;
    max-width: 74px;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding: 1rem;
    padding-left: 0;
    .drName {
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 32px;
    }
    .drData {
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
  p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`
