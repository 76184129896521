import styled from 'styled-components'

export const Li = styled.li``

export const Anchor = styled.a`
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 400;
  color: #fff;
  font-size: ${({ isDesktop }) => (isDesktop ? '0.9rem' : '16px')};
`
