import * as React from 'react'
import { MOBILE_WIDTH } from '~/utils/constants';
import useMediaQuery from '~/hooks/useMediaQuery';
import * as Style from './styles';
import imgHeader from '~/assets/images/newhome/bannerHome.png'
import CannectSection from '~/components/Home/CannectSection/index'
import LearningTrails from '~/components/Home/LearningTrails/index';
import Testimonials from '~/components/Home/Testimonials/index';
import Oportunities from '~/components/Home/Oportunities/index';
import Numbers from '~/components/Home/Numbers/index';
import Events from '~/components/Home/Events/index';
import NewButton from '~/components/Home/NewButton/index';

export default function HomeV2() {

  const isMobile = useMediaQuery(MOBILE_WIDTH);

  return (
    <Style.Wrapper isDesktop={!isMobile} >
      <Style.Container isDesktop={!isMobile} >
        <Style.Header isDesktop={!isMobile}>
          {!isMobile && (
            <div className='headerContainer'>
            <h2>
              A educação é<br />o melhor remédio.
            </h2>
            <div>
              <h2>
                O<span className='black'> mercado evolui muito rápido,</span> as nossas <span className='green'>jornadas de conhecimento </span> também.
              </h2>
            </div>
            {/* <a href="https://educacao.drcannabis.com.br/" target='_blank'>
              <button>VEJA OS NOSSOS CURSOS</button>
            </a> */}
            <NewButton href="https://educacao.drcannabis.com.br/" newTab label="VEJA OS NOSSOS CURSOS" />
          </div>
          )}
          {isMobile && (
            <div className='mobileHeaderContainer'>
            <h2>
              A educação é<br />o melhor remédio.
            </h2>
            <div>
              <h2>
                O<span className='black'> mercado evolui muito rápido,</span> as nossas <span className='green'>jornadas de conhecimento </span> também.
              </h2>
            </div>
            {/* <a href="https://educacao.drcannabis.com.br/" target='_blank'>
              <button>VEJA OS NOSSOS CURSOS</button>
            </a> */}
            <div>
              <NewButton href="https://educacao.drcannabis.com.br/" newTab label="VEJA OS NOSSOS CURSOS" />
            </div>
          </div>
          )}
          {
            !isMobile && (
              <div className='rigth'>
                <img src={imgHeader} alt="" />
              </div>
            )
          }
        </Style.Header>
        <CannectSection />
        <LearningTrails />
        <Testimonials />
        <Oportunities />
        <Numbers />
        <section id="eventosSection">
          <Events />
        </section>
      </Style.Container>
    </Style.Wrapper>
  )
}
