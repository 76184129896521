import styled from 'styled-components'

export const Container = styled.div`
  padding: ${({ isDesktop }) => (isDesktop ? '1rem 2.5rem' : '0 20px')};
  #moreCourses {
    margin-top: 25px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;

    a {
      text-decoration: none;
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 27px;
      color: #243258;
      display: flex;
      align-items: stretch;
      justify-content: flex-end;
      gap: 0.1rem;
      span {
        margin-left: 5px;
      }
    }
  }
`

export const Wrapper = styled.div``

export const Header = styled.div`
  font-family: 'Spartan';
  font-size: 52px;
  font-weight: 700;
  h1 {
    font-size: 2rem;
  }
`

export const HeaderContainer = styled.div``

export const HeaderTitle = styled.h2``

export const HeaderTitleSpan = styled.span``

export const CardsContainer = styled.div`
  margin-top: 1rem;
  width: ${({ isDesktop }) => (isDesktop ? '100%' : '')};
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'row')};
  flex-wrap: nowrap;
  justify-content: ${({ isDesktop }) =>
    isDesktop ? 'space-between' : 'flex-start'};
  overflow-x: ${({ isDesktop }) => (isDesktop ? '' : 'scroll')};
  gap: ${({ isDesktop }) => (isDesktop ? '0' : '2rem')};
`

export const Card = styled.div`
  flex-basis: ${({ isDesktop }) => (isDesktop ? '30%' : '100%')};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${({ isDesktop }) => (isDesktop ? '350px' : '390px')};
  max-width: ${({ isDesktop }) => (isDesktop ? '350px' : '390px')};
  min-height: 620px;
  max-height: 774px;
  background: #ffffff;
  box-shadow: 0px 4.32963px 22.7305px rgba(36, 50, 88, 0.11);
  border-radius: 20px;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  gap: 2rem;
  &:hover {
    transform: scale(1.05);
  }
  position: relative;
  /* padding-bottom: 5rem; */
  a {
    position: absolute;
    bottom: 1rem;
    /* width: 85%; */
  }
  button {
    /* position: absolute;
    bottom: 1rem;
    width: 85%; */
    /* width: 85%;
    height: 66px;
    background: #1c2960;
    box-shadow: 7.28121px 8.32138px 32.2454px rgba(20, 62, 101, 0.11);
    border-radius: 10.4017px;
    color: #fff;
    border: none; */
    /* padding: 0 1rem; */

    /* font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 18px; */
    /* identical to box height */

    font-size: 12px !important;
    text-transform: uppercase;
    width: 286px;
    max-width: 300px;

    /* color: #ffffff; */
  }
`

export const CardHeader = styled.div`
  /* padding-top: 2rem; */
  img {
    width: 100%;
    border-radius: 1rem;
  }
`

export const CardBody = styled.div`
  h3 {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 29px;
    color: #66af70;
    margin-bottom: 0.5rem;
  }
  p {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 30px;

    color: #272042;
  }
`

export const MobileWrapper = styled.div`
  display: flex;
  width: ${({ isDesktop }) => (isDesktop ? '100%' : '350%')};
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'row')};
  flex-wrap: nowrap;
  justify-content: ${({ isDesktop }) =>
    isDesktop ? 'space-between' : 'flex-start'};
  overflow-x: ${({ isDesktop }) => (isDesktop ? 'hidden' : 'scroll')};
  gap: ${({ isDesktop }) => (isDesktop ? '0' : '2rem')};
`

export const CardImage = styled.img``

export const CardTitle = styled.h3``

export const CardDescription = styled.p``

export const CardButton = styled.button``

export const CardButtonSpan = styled.span``

export const CardButtonIcon = styled.img``

export const CardButtonIconSpan = styled.span``
