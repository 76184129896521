import styled from 'styled-components'

export const Wrapper = styled.div`
  h1 {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 27px;
    color: #ffffff;
  }
`

export const Container = styled.div`
  ul {
    margin-top: 2rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`
