import React from "react"
import { MOBILE_WIDTH } from "~/utils/constants"
import useMediaQuery from "~/hooks/useMediaQuery"
import drJimmy from '~/assets/images/newhome/drJimmy.png';
import draMariana from '~/assets/images/newhome/draMariana.png';
import draJuliana from '~/assets/images/newhome/draJuliana.png';
import * as Styled from "./styles"
export default function TtMobile() {
    const isMobile = useMediaQuery(MOBILE_WIDTH)
    return (
        <Styled.Wrapper> 
            <Styled.Container>
                <Styled.CardTestimonial isDesktop={!isMobile}>
                    <img src={draJuliana} alt="" width={74} />
                    <div>
                        <p className='drName'>Dra. Juliana Paiva</p>
                        <p className='drData'>Médica e aluna do curso <br/>Cannabis Medicinal do Zero</p>
                    </div>
                    <p>“Eu comecei a prescrever logo que acabei o curso. E hoje tenho centenas de pacientes com terapia em curso.”</p>
                </Styled.CardTestimonial>
                <Styled.CardTestimonial isDesktop={!isMobile}>
                    <img src={drJimmy} alt="" width={74} />
                    <div>
                        <p className='drName'>Dr. Jimmy Fardin</p>
                        <p className='drData'>Médico e participante <br/>do congresso CNABIS</p>
                    </div>
                    <p>"Realmente vocês estão de parabéns! Evento muito interessante, com formato inovador e com conteúdo fantástico. Obrigado!"</p>
                </Styled.CardTestimonial>
                <Styled.CardTestimonial isDesktop={!isMobile}>
                    <img src={draMariana} alt="" width={74} />
                    <div>
                        <p className='drName'>Dra. Mariana Gonçalves</p>
                        <p className='drData'>Médica e aluna do curso <br/>Cannabis Medicinal do Zero</p>
                    </div>
                    <p>“O curso é uma grande porta de entrada e uma boa capacitação para nós médicos começarmos a prescrever.”</p>
                </Styled.CardTestimonial>
            </Styled.Container>
        </Styled.Wrapper>
    )
}