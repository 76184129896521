import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { toast } from 'react-toastify'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-credit-cards/es/styles-compiled.css'
import 'react-widgets/styles.css'
import 'simplebar/src/simplebar.css'

import Routes from './routes'
import store, { history } from './store'
import GlobalStyle from './styles/global'
import Header from './components/Header'
import Footer from './components/Footer'
import { WrapperWhatsapp } from './components/WrapperWhatsapp'
import ScrollToTop from './components/ScrollToTop/index'
import TagManager from 'react-gtm-module'
import NewHeader from './components/NewHeader/index'
import NewFooter from './components/NewFooter/index'

function App() {
  toast.configure({
    autoClose: 8000,
    hideProgressBar: true,
    newestOnTop: true,
  })

  const tagManagerArgs = {
    // gtmId: process.env.GTM_ID
    gtmId: 'GTM-59529VR',
  }
  TagManager.initialize(tagManagerArgs)

  return (
    <Provider store={store}>
      <GlobalStyle />
      <ConnectedRouter history={history}>
        <>
          <WrapperWhatsapp />
          {/* <Header /> */}
          <NewHeader />
          <Routes />
          {/* <Footer /> */}
          <NewFooter />
          <ScrollToTop />
        </>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
