import img from '../../../assets/images/newhome/ilustracao-cannabis.png'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 100px;
  padding: ${({ isDesktop }) => (isDesktop ? '0 2.5rem' : '0 20px')};
  position: relative;
  h1 {
    display: inline-block;
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: ${({ isDesktop }) => (isDesktop ? '63px' : '30px')};
    color: #444444;
    ::after {
      position: absolute;
      top: -100px;
      left: 670px;
      content: '';
      background-image: url(${img});
      display: ${({ isDesktop }) => (isDesktop ? 'block' : 'none')};
      background-size: 150px;
      background-repeat: no-repeat;
      width: 150px;
      height: 150px;
    }
    margin-bottom: 1rem;
  }
  #buttonBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 0 60px 0;
    a {
      button {
        width: 326px;
        display: block !important;
        margin: ${({ isDesktop }) =>
          isDesktop ? '0 auto !important' : '3rem auto !important'};
        margin-bottom: 1rem;
        text-transform: uppercase;
        /* display: block;
        margin: 1rem auto;
        padding: 0.9rem;
        border: none;
        background: #1c2960;
        box-shadow: 7.60685px 8.69355px 33.6875px rgba(20, 62, 101, 0.11);
        border-radius: 10.8669px;

        font-family: 'Spartan';
        text-transform: uppercase;
        font-style: normal;
        font-weight: 700;
        font-size: 0.9rem; */
        /* line-height: 24px; */

        /* color: #ffffff;
        margin-bottom: 3rem; */
      }
    }
  }
`
export const Container = styled.div`
  p {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 600;
    font-size: ${({ isDesktop }) => (isDesktop ? '1rem' : '25px')};
    line-height: ${({ isDesktop }) => (isDesktop ? '1.5rem' : '42px')};
    color: #444444;

    span {
      color: #5daa60;
      font-weight: ${({ isDesktop }) => (isDesktop ? '500' : '600')};
    }
  }
`

export const NumbersWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  overflow-x: ${({ isDesktop }) => (isDesktop ? '' : 'scroll')};
  ::-webkit-scrollbar {
    display: none;
  }
  /* margin: 2rem 0; */
`
export const NumbersContainer = styled.div`
  display: flex;
  gap: 2rem;
`

export const NumberCountainer = styled.div`
  /* width: 490px; */
  width: 350px;
  height: 226px;
  padding: 30px 46px;

  background: #ffffff;
  box-shadow: 0px 4.41021px 14px rgba(36, 50, 88, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .number {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    /* line-height: 44px; */
    text-align: center;

    color: #5daa60;
  }
  .text {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 4.5%;
    color: #444;
  }
`
