import React, { useState, useEffect } from "react";
import logo from "~/assets/images/newfooter/dr-cannabis-logo-footer.png";
import FooterNewsletter from "../FooterNewsletter/index";
import FooterLinks from "./FooterLinks/index";
import * as Styled from "./styles";
import { MAX_WIDTH, MOBILE_WIDTH } from '~/utils/constants'
import useMediaQuery from "~/hooks/useMediaQuery";
import TalkToUs from "./TalkToUs/index";

export default function NewFooter() {

    const isMobile = useMediaQuery(MOBILE_WIDTH);
    const [bigScreen, setBigScreen] = useState(false);
    
    useEffect(() => {
        if (window.innerWidth > MAX_WIDTH) {
            setBigScreen(true);
        }
    });

    console.log('KELVINN ',bigScreen);
    console.log('KELVINN ',window.innerWidth);
    return (
        <Styled.Wrapper bigScreen={bigScreen} isDesktop={!isMobile} >
            <Styled.MainContainer bigScreen={bigScreen} isDesktop={!isMobile}>
                <Styled.ImgContainer isDesktop={!isMobile}>
                    <img src={logo} alt="" />
                </Styled.ImgContainer>
                <Styled.Container isDesktop={!isMobile}>
                    <FooterNewsletter />
                    <Styled.Right isDesktop={!isMobile}>
                        {!isMobile && <FooterLinks />}
                        <TalkToUs />
                    </Styled.Right>
                </Styled.Container>
            </Styled.MainContainer>
        </Styled.Wrapper>
    )
}