import React from "react";
import { MOBILE_WIDTH } from "~/utils/constants";
import useMediaQuery from "~/hooks/useMediaQuery";
import cardImgOne from '~/assets/images/newhome/cardImgOne.png';
import cardImgTwo from '~/assets/images/newhome/cardImgTwo.png';
import cardImgThree from '~/assets/images/newhome/cardImgThree.png';
import * as Styled from "./styles";
import NewButton from "../../NewButton/index";

export default function LTMobile() {
  const isMobile = useMediaQuery(MOBILE_WIDTH);
  return (
    <Styled.Wrapper isDesktop={!isMobile}>
      <Styled.Card isDesktop={!isMobile}>
        <Styled.CardHeader isDesktop={!isMobile}>
              <img src={cardImgOne} alt="" />
          </Styled.CardHeader>
          <Styled.CardBody isDesktop={!isMobile}>
              <h3>Negócios</h3>
              <p>
              Um mercado com capacidade de gerar 300 mil empregos e movimentar 13 milhões de dólares no Brasil está em formação. 
  Empreendendo ou trabalhando na área, comece hoje a se destacar.
              </p>
              {/* <button>Quero trabalhar com Cannabis</button> */}
          </Styled.CardBody>
          {/* <NewButton label="Quero trabalhar com Cannabis"  /> */}
      </Styled.Card>
      <Styled.Card isDesktop={!isMobile}>
          <Styled.CardHeader isDesktop={!isMobile}>
              <img src={cardImgTwo} alt="" />
          </Styled.CardHeader>
          <Styled.CardBody isDesktop={!isMobile}>
              <h3>Médicos e odontologistas</h3>
              <p>
              Prescrever canabinoides é legal desde 2015, mas apenas 1% dos profissionais que poderiam já prescrevem na prática.
  Seja pioneiro e se diferencie!
              </p>
          </Styled.CardBody>
          {/* <NewButton label="Quero ser prescritor"/> */}
      </Styled.Card>
      <Styled.Card isDesktop={!isMobile}>
          <Styled.CardHeader isDesktop={!isMobile}>
              <img src={cardImgThree} alt="" />
          </Styled.CardHeader>
          <Styled.CardBody isDesktop={!isMobile}>
              <h3>Profissionais da Saúde</h3>
              <p>
              Depois da prescrição, a resposta terapêutica é percebida pelo paciente. Psicólogos e enfermeiros precisam estar prontos para acolher essas mudanças.
              </p>
          </Styled.CardBody>
          {/* <button>Quero me capacitar</button> */}
          {/* <NewButton label="Quero me capacitar"  /> */}
      </Styled.Card>
    </Styled.Wrapper >             
  )
}