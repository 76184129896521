import React from "react";
import * as Styled from "./styles";
import { MOBILE_WIDTH } from "~/utils/constants";
import useMediaQuery from "~/hooks/useMediaQuery";
export default function NavMenu() {
  const isMobile = useMediaQuery(MOBILE_WIDTH);
  return (
    <Styled.Nav isDesktop={!isMobile}>
        <Styled.Ul isDesktop={!isMobile}>
            <Styled.Li isDesktop={!isMobile}>
                <a href="/">
                    Home
                </a>
            </Styled.Li>
            <Styled.Li isDesktop={!isMobile}>
                <a href="https://educacao.drcannabis.com.br/" target="_blank">
                    Cursos
                </a>
            </Styled.Li>
            <Styled.Li isDesktop={!isMobile}>
                <a href="/#eventosSection" target="_parent">
                    Eventos
                </a>
            </Styled.Li>
            <Styled.Li isDesktop={!isMobile}>
                <a href="https://www.cannect.life/" target="_blank">
                    Conheça a Cannect
                </a>
            </Styled.Li>
            <Styled.Li isDesktop={!isMobile}>
                <a href="/faq">
                    FAQ
                </a>
            </Styled.Li>
            <Styled.Li isDesktop={!isMobile}>
                <a href="https://blog.drcannabis.com.br/" target="_blank">
                    Blog
                </a>
            </Styled.Li>
        </Styled.Ul>
    </Styled.Nav>
  );
}