import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: ${({ isDesktop }) => (isDesktop ? '1200px' : '100%')};
  padding: 0 2.5rem;
  h1 {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
  }
`

export const Container = styled.div`
  margin-top: 50px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  align-items: flex-end;
  img {
    /* width: 100%; */
    max-width: 100%;
    max-height: 332px;
  }

  /* height: 600px; */
  margin-bottom: 1rem;
  .text {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */

    align-items: flex-end;
    justify-content: flex-start;
    align-content: center;

    div {
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: flex-end;
      align-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        column-gap: 3rem;
        justify-content: center;
        align-items: flex-start;
        align-content: center;
        color: #0d1733;
        h3 {
          font-family: 'Spartan';
          font-weight: 600;
          font-size: 1.3rem;
          line-height: 38px;
        }
        p {
          font-family: 'Nunito';
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 28px;
          text-align: justify;
          letter-spacing: -0.015em;
        }
      }
    }
  }
`
