import styled from 'styled-components'

export const Nav = styled.nav``

export const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 1rem;
`

export const Li = styled.li``
