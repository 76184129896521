import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const SectionNewsletter = styled.section`
  /* background-color: ${V.colorGreyLighter}; */

  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 1rem;
  /* padding: 2rem 0; */
  padding-top: 0 !important;
  padding-left: ${({ isDesktop }) => (isDesktop ? '' : '0 !important')};
  form {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 2rem;
    width: 100%;

    div {
      div {
        button {
          padding: 12px 20px;
          width: 50%;
          background: #1C2960;
          color: #fff;
          font-family: 'Spartan';
          font-style: normal;
          font-weight: 500;
          font-size: 14.8456px;
          line-height: 17px;
          /* identical to box height */

          text-transform: uppercase;

          border-radius: 7.4228px;
        }
        margin: 1rem 0;
      }
    }
    
  }

  .title {
    color: #fff;
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: ${({ isDesktop }) => (isDesktop ? '1rem' : '20px')};
    line-height: 24px;


    span {
      font-weight: 600;
    }
    @media only screen and (max-width: 575px) {
      /* color: ${V.colorBlackDark}; */
      /* line-height: 1.5; */
    }
  }

  .blue {
    margin-top: 1rem;
  }

  .box {
    &.md {
      width: 16.3rem;
      height: 3.2rem;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &.lg {
      width: 20rem;
      height: 3.7rem;
      @media only screen and (max-width: 575px) {
        width: 100%;
        gap: 1rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
      }
    }

    &.xl {
      width: 32rem;
      height: 3.2rem;
      @media only screen and (max-width: 575px) {
        width: 14.3rem;
      }
    }
  }

  .text,
  .dropdown {
    width: 100%;
    height: 100%;
    border: 1px solid ${V.colorGreyDark};
    border-radius: 5px;
    background-color: ${V.colorWhite};
  }

  .text {
    &::placeholder {
      font-family: Roboto;
      font-size: 1.1rem;
      color: ${V.colorGreyDark};
    }
    &.light {
      &::placeholder {
        font-family: Roboto;
        font-size: 0.9rem;
        color: ${V.colorGreyLight};
      }
    }
  }

  .dropdown {
    appearance: none;
    -webkit-appearance: none;
  }

  .icon {
    width: 1.1rem;
    height: 1.1rem;
    fill: ${V.colorBlackDark};
    top: 50%;
    transform: translateY(-50%);

    &.left {
      left: 1rem;
    }

    &.right {
      right: 1rem;
    }
  }
`
