import React, { useState } from "react";
import useMediaQuery from "~/hooks/useMediaQuery";
import { MOBILE_WIDTH } from "~/utils/constants";
import MobileMenu from "~/assets/svg/MobileMenu.svg";
import MobileClose from "~/assets/svg/MobileClose.svg";
import logoDrCannabis from "~/assets/images/newheader/logo-dr-cannabis.png";
import * as Styled from "./styles";
import NavMenu from "./NavMenu/index";

export default function NewHeader() {

    const isMobile = useMediaQuery(MOBILE_WIDTH);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <Styled.Wrapper isDesktop={!isMobile} >
            <Styled.Container isDesktop={!isMobile} >
                <img src={logoDrCannabis} alt="" />
                <NavMenu />
                <a href="/login">
                    <button>Entrar</button>
                </a>
            </Styled.Container>
            {isMobile && (
                <Styled.MobileMenu isDesktop={!isMobile} >
                    <img src={logoDrCannabis} alt="" />
                    <Styled.MobileMenuContent isMenuOpen={isMenuOpen}>
                        <img className="mobileMenuToggle" src={MobileClose} alt="" onClick={handleMenuClick}/>
                        <NavMenu />
                        <a href="/login">
                            <button>Entrar</button>
                        </a>
                    </Styled.MobileMenuContent>
                    {isMenuOpen ? <img className="mobileMenuToggle" src={MobileClose} alt="" onClick={handleMenuClick} /> : <img className="mobileMenuToggle" src={MobileMenu} alt="" onClick={handleMenuClick}/> }
                </Styled.MobileMenu>
            )}
        </Styled.Wrapper>
    )
}