import React from 'react';
import { MOBILE_WIDTH } from '~/utils/constants';
import useMediaQuery from '~/hooks/useMediaQuery';
import drJimmy from '~/assets/images/newhome/drJimmy.png';
import draMariana from '~/assets/images/newhome/draMariana.png';
import draJuliana from '~/assets/images/newhome/draJuliana.png';
import * as Styled from './styles';
import NewButton from '../NewButton/index';
import TtMobile from './TTMobile/index';

export default function Testimonials() {
    const isMobile = useMediaQuery(MOBILE_WIDTH);
    return (
        <Styled.Wrapper isDesktop={!isMobile}>
            <h1>Veja os depoimentos de quem já estudou conosco</h1>
            <Styled.Container isDesktop={!isMobile}>
                <Styled.Card isDesktop={!isMobile}>
                    {/* <Styled.CardText>Lorem ipsum</Styled.CardText> */}
                    <iframe 
                    src="https://player.vimeo.com/video/699105318?loop=false&amp;autoplay=false&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false" 
                    allowfullscreen="" 
                    allowtransparency="" 
                    allow="autoplay" 
                    title="Player for Dra Mariana Gonçalves" 
                    data-ready="true" 
                    tabindex="-1"></iframe>
                    <Styled.CardText isDesktop={!isMobile}>
                        <h3>"Recomendei a vários colegas"</h3>
                        <p>Dra. Mariana Gonçalves<br />
                        Aluna CMZ turma 1</p>
                    </Styled.CardText>
                </Styled.Card>
                <Styled.Card isDesktop={!isMobile}>
                    <iframe 
                    src="https://player.vimeo.com/video/696952973?loop=false&amp;autoplay=false&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false" 
                    allowfullscreen="" 
                    allowtransparency="" 
                    allow="autoplay" 
                    title="Player for Dra. Juliana - Depoimento - Curso Cannabis Medicinal do Zero" 
                    data-ready="true" 
                    tabindex="-1"></iframe>
                    {/* <div>
                    </div> */}
                        <Styled.CardText isDesktop={!isMobile}>
                        <div>
                        <h3>"Durante a faculdade de medicina, eu não aprendi nada sobre cannabis medicinal"</h3>
                        <p>Dra. Juliana Sicoli<br />
                        Aluna CMZ turma 1</p>
                        </div>
                        </Styled.CardText>
                </Styled.Card>
            </Styled.Container>
            <Styled.TestiMonialWrapper isDesktop={!isMobile}>
                {
                    isMobile ? <TtMobile />
                     : (
                        <>
                            <Styled.CardTestimonial isDesktop={!isMobile}>
                                <img src={draJuliana} alt="" width={74} />
                                <div>
                                    <p className='drName'>Dra. Juliana Paiva</p>
                                    <p className='drData'>Médica e aluna do curso <br/>Cannabis Medicinal do Zero</p>
                                </div>
                                <p>“Eu comecei a prescrever logo que acabei o curso. E hoje tenho centenas de pacientes com terapia em curso.”</p>
                            </Styled.CardTestimonial>
                            <Styled.CardTestimonial isDesktop={!isMobile}>
                                <img src={drJimmy} alt="" width={74} />
                                <div>
                                    <p className='drName'>Dr. Jimmy Fardin</p>
                                    <p className='drData'>Médico e participante <br/>do congresso CNABIS</p>
                                </div>
                                <p>"Realmente vocês estão de parabéns! Evento muito interessante, com formato inovador e com conteúdo fantástico. Obrigado!"</p>
                            </Styled.CardTestimonial>
                            <Styled.CardTestimonial isDesktop={!isMobile}>
                                <img src={draMariana} alt="" width={74} />
                                <div>
                                    <p className='drName'>Dra. Mariana Gonçalves</p>
                                    <p className='drData'>Médica e aluna do curso <br/>Cannabis Medicinal do Zero</p>
                                </div>
                                <p>“O curso é uma grande porta de entrada e uma boa capacitação para nós médicos começarmos a prescrever.”</p>
                            </Styled.CardTestimonial>
                        </>
                    )
                }
            </Styled.TestiMonialWrapper>
            {/* <a href="https://educacao.drcannabis.com.br/" target='_blank'>
                <button>Veja os cursos</button>
            </a> */}
            <NewButton href="https://educacao.drcannabis.com.br/" newTab label="Veja os cursos" />
        </Styled.Wrapper>
    )
}