import React from "react";
import { MOBILE_WIDTH } from "~/utils/constants";
import useMediaQuery from "~/hooks/useMediaQuery";

import * as Styled from "./styles";
import FooterLinks from "../FooterLinks/index";

export default function TalkToUs() {

    const isMobile = useMediaQuery(MOBILE_WIDTH);
    return (
        <>
            <Styled.Wrapper isDesktop={!isMobile}>
                <div>
                    <h1>Fale conosco:</h1>
                    {isMobile && <span>contato@drcannabis.com.br</span>}
                </div>
                <Styled.Container isDesktop={!isMobile}>
                    <div>
                        {!isMobile && <span>contato@drcannabis.com.br</span>}
                    {/* <h1>Fale conosco</h1>
                    <span>contato@drcannabis.com.br</span> */}
                        <p>Redes sociais:</p>
                        <Styled.SocialLinks isDesktop={!isMobile}>
                            <Styled.SocialWrapp isDesktop={!isMobile}>
                                <a href="https://www.facebook.com/drcannabs" target="_blank">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="https://twitter.com/drcannabisreal" target="_blank">
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </Styled.SocialWrapp>
                            <Styled.SocialWrapp isDesktop={!isMobile}>
                                <a href="https://www.instagram.com/drcannabisoficial/" target="_blank">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a href="https://br.linkedin.com/company/dr-cannabis" target="_blank">
                                    <i className="fab fa-linkedin-in"></i>  
                                </a>
                            </Styled.SocialWrapp>
                        </Styled.SocialLinks>
                    </div>
                    <div>
                        {isMobile && <FooterLinks />}
                    </div>
                </Styled.Container>
            </Styled.Wrapper>
        </>
        
    )
}