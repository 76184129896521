import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: ${({ isDesktop }) => (isDesktop ? '0 3rem' : '0')};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h1 {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 500;
    font-size: ${({ isDesktop }) => (isDesktop ? '1rem' : '18px')};
    line-height: 27px;
    color: #ffffff;
  }
  span,
  p {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #fff;
    margin: 1rem 0;
    font-size: ${({ isDesktop }) => (isDesktop ? '1rem' : '18px')};
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'column' : 'row-reverse')};
  align-items: baseline;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: ${({ isDesktop }) => (isDesktop ? 'flex-end' : 'center')};
  div {
    span,
    p {
      font-family: 'Spartan';
      font-style: normal;
      font-weight: ${({ isDesktop }) => (isDesktop ? '400' : '500')};
      text-align: justify;
      font-size: ${({ isDesktop }) => (isDesktop ? '1rem' : '18px')};
      letter-spacing: -0.015em;
      color: #fff;
      margin: 1rem 0;
    }
  }
  span {
    font-size: 1.2rem;
    line-height: 40px;
  }
  p {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    letter-spacing: normal;
  }
  div {
    flex-basis: 50%;
  }
`

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  gap: ${({ isDesktop }) => (isDesktop ? '0.5rem' : '1.5rem')};
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ isDesktop }) => (isDesktop ? '2.5rem' : '3.7rem')};
    height: ${({ isDesktop }) => (isDesktop ? '2.5rem' : '3.7rem')};
    border-radius: 50%;
    background: #ffffff;
    font-size: ${({ isDesktop }) => (isDesktop ? '1rem' : '18px')};
  }
`
export const SocialWrapp = styled.div`
  display: flex;
  gap: ${({ isDesktop }) => (isDesktop ? '0.5rem' : '1.5rem')};
  justify-content: ${({ isDesktop }) => (isDesktop ? 'space-around' : '')};
`
