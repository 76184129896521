import React from "react";
import * as Styled from "./styles";

export default function NewButton({ href, label, children, outlined, newTab, ...props }) {
  return (
    <a href={href} target={newTab ? '_blank' : '_parent'}>
        <Styled.Container outlined={outlined}>
            {label}
        </Styled.Container>
    </a>
  );
}