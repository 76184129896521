import styled from 'styled-components'
import img from '../../assets/images/newhome/ilustracao-cannabis.png'
import bannerHomeMobile from '../../assets/images/newhome/bannerHomeMobile.png'

export const Wrapper = styled.div`
  /* filter: blur(332px);
  background: #ffffff;
  z-index: 0; */
  background: radial-gradient(circle at center, #eefbf3 0, #fff 100%);
`

export const Container = styled.div`
  max-width: ${({ isDesktop }) => (isDesktop ? '1200px' : '100%')};
  margin: 0 auto;
  /* font-family: 'League Spartan', sans-serif;
  font-family: 'Nunito', sans-serif; */
  /* max-width: 1080px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto; */
  /* background: #000; */
  z-index: 99;
`

export const Header = styled.div`
  height: 80vh;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 50px 60px;
  padding-bottom: 0; */
  background: ${({ isDesktop }) =>
    isDesktop ? '' : `url(${bannerHomeMobile}) no-repeat center`};
  background-size: cover;
  mix-blend-mode: multiply;
  .mobileHeaderContainer {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    width: 100%;
    height: 100%;
    gap: 4rem;

    h2 {
      color: #fff;
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    div {
      h2 {
        font-family: 'Spartan';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 46px;
        span {
          font-weight: 600;
        }
      }
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      width: 100%;
      a {
        width: 100%;
        display: block;
        margin: 0 auto;
        button {
          width: 100%;
        }
      }
    }
  }
  .headerContainer {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: ${({ isDesktop }) => (isDesktop ? '100%' : '')};
    max-width: 520px;
    padding-bottom: 20px;
    h2 {
      /* font-family: 'Spartan';
      font-size: 35px;
      color: #444;
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      line-height: 50px;
      max-width: 511px;
      text-align: left;

      font-family: 'Spartan';
      font-size: 35px;
      font-weight: 500;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: left; */
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 600;
      font-size: 1.1rem;
      line-height: 28px;
      color: #444444;
      margin-bottom: 10px;

      /* b {
        font-family: 'Spartan';
        font-size: 35px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: left;
      } */
    }
    div {
      position: relative;
      h2 {
        ::after {
          position: absolute;
          top: -110px;
          right: 0;
          content: '';
          background-image: url(${img});
          display: ${({ isDesktop }) => (isDesktop ? 'block' : 'none')};
          background-size: 150px;
          background-repeat: no-repeat;
          width: 150px;
          height: 150px;
        }
        font-family: 'Spartan';
        font-size: 1.7rem;
        font-weight: 400;
        /* line-height: 66px; */
        line-height: 36px;
        letter-spacing: 1px;
        text-align: left;
        span.green {
          color: #66af70;
          font-family: 'Spartan';
          font-weight: 600;
          text-align: left;
        }
        span.black {
          color: #444;
          font-family: 'Spartan';
          font-weight: 600;
          /* line-height: 50px; */
        }
      }
    }
    button {
      /* width: 325px;
      height: 42px; */
      /* outline: none;
      border: none;
      color: #fff;
      background: #1c2960;
      box-shadow: 7.28121px 8.32138px 32.2454px rgba(20, 62, 101, 0.11);
      border-radius: 10.4017px;
      font-family: 'Spartan';
      font-style: normal;
      font-weight: 700;
      font-size: 0.9rem; */
      /* line-height: 24px; */
      text-transform: uppercase;
    }
  }
  .rigth {
    /* width: 50%; */
    /* float: right; */
    img {
      width: 100%;
      /* max-width: 50vw; */
    }
  }
`
