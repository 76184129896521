import React from "react";
import * as Styled from "./styles";
import { MOBILE_WIDTH } from "~/utils/constants";
import useMediaQuery from "~/hooks/useMediaQuery";

export default function FooterLinkComponent({link, title, target}) {
    const isMobile = useMediaQuery(MOBILE_WIDTH);
    return (
        <Styled.Li isDesktop={!isMobile}>
            <Styled.Anchor href={link} target={target} isDesktop={!isMobile}>
                {title}
            </Styled.Anchor>
        </Styled.Li>
    )
}